<template>
  <div class="product">
    <div class="content" id="fullpage">
      <full-page :options="options" ref="fullpage2">
        <template v-for="(item, index) in obj">
          <div
            class="content-item section active"
            :ref="'fullpage' + index"
            :key="item.itemTitle"
          >
            <div class="fp-tableCell">
              <div class="content-item-title" :ref="'top' + index">
                {{ item.itemTitle }}
              </div>
              <div
                class="content-item-img"
                :ref="'imgB' + index"
                :style="{ height: imgBigHeight }"
              >
                <img
                  v-show="isShow"
                  :ref="'img' + index"
                  :class="['opt', DefaultIndex === index ? 'active' : '']"
                  :style="{ width: imgWidth, height: imgHeight }"
                  :src="item.itemSrcImg"
                  v-lazy="item.itemLazyImg"
                  img-type="pc-phone"
                  alt=""
                />
              </div>
              <div
                @click="toDetails(index)"
                class="content-item-img-bottom"
                :ref="'bottom' + index"
              >
                {{ $t(item.itemImgBottom) }}
              </div>
              <div class="arrow1" @click="arrownext1()">
                <img :src="item.itemimgDown" />
              </div>
            </div>
          </div>
        </template>
        <div class="section" style="background: #fbfbfb">
          <pc-footer></pc-footer>
        </div>
      </full-page>
    </div>
    <to-top />
  </div>
</template>

<script>
import ToTop from "../../component/totop/toTop.vue";
import pcFooter from "../../component/footer/phoneFooter";
import { mapState } from "vuex";
export default {
  name: "product",
  data() {
    return {
      obj: [
        {
          itemTitle: "QiCYCLE F2",
          itemSrcImg: require("@/assets/newQJ/index/image/bike/bike2.png"),
          itemLazyImg: require("@/assets/newQJ/index/image/F2.png"),
          itemImgBottom: "product.v1.productMove",
          itemimgDown: require("@/assets/newQJ/index/image/down2.png"),
        },
        {
          itemTitle: "QiCYCLE C2",
          itemSrcImg: require("@/assets/newQJ/index/image/bike/bike1.png"),
          itemLazyImg: require("@/assets/newQJ/index/image/c2.png"),
          itemImgBottom: "product.v1.productMove",
          itemimgDown: require("@/assets/newQJ/index/image/down2.png"),
        },
      ],
      status: true,
      DefaultIndex: 0,
      options: {
        //API接口
        //配置背景颜色
        //配置幻灯片切换是否显示箭头，默认true
        controlArrows: false,
        //配置每页内容页面是否垂直居中，默认false
        verticalCentered: true,
        // 配置页面滚动速度,默认700
        scrollingSpeed: 700,
        //配置锚链接，不应该和autoScrolling，scrollBar一起使用
        //anchors: ["/phone/product", "/phone/product", "/phone/product"],
        //配置锚点切换时候是否有过度动画，默认true
        animateAnchor: false,
        //锁定配置的锚链接是否显示，默认false
        lokAnchors: true,
        //配置动画由css3还是jquery来执行，默认true
        css3: true,
        easing: "easeInQuart",
        //配置滚动到顶部之后是否从底部循环滚动，默认false
        // loopTop: true,
        //相反从底部循环滚动，默认false
        // loopBottom: true,
        //设置页面是否循环滚动与loopTop，loopBottom不兼容,默认是false
        // continuousVertical: true,
        //幻灯片是否循环滚动，默认true
        loopHorizontal: false,
        //配置是否按照插件的方式来进行滚动，默认true,和锚点不应该一起使用，一般不设置或者保持默认
        autoScrolling: true,
        //滚动的时候是否包含滚动条,默认false,和锚点不应该一起使用，一般不设置或者保持默认
        scrollBar: false,
        //配置页面上下间距，默认0，如果需要设置固定顶部和顶部菜单导航配合使用
        // paddingTop：'300px',
        paddingBottom: 0,
        //配置页面是否有固定的DOM
        // fixedElements: '#header',
        //配置是否支持键盘方向键控制页面切换，默认true
        keyboardScrolling: true,
        //配置是否激活浏览器前进后退功能，如果页面设置了不按照插件的方式来滚动该配置也失效，默认true
        recordHistory: false,
        //配置导航,位置，提示,显示当前位置
        navigation: true,
        navigationPosition: "left",
        // navigationTooltips: ['EC1', 'page2', 'page3', 'page4'],
        showActiveTooltip: true,
        //配置幻灯片是否显示导航,和位置
        slidesNavigation: true,
        slidesNavPosition: "bottom",
        //配置内容超过容器是否显示滚动条，模式true,
        scrollOverflow: false,
        afterLoad: (before, after) => {
          this.DefaultIndex = after.index;
          this.$store.state.index = after.index;
        },
      },
      imgWidth: "auto",
      imgHeight: "100%",
      imgBigHeight: "500px",
      isShow: false,
    };
  },
  computed: {
    ...mapState(["scroll"]),
  },
  watch: {
    scroll() {
      if (this.$store.state.scroll != 0) {
        this.$refs.fullpage2.api.moveTo(1);
      }
      this.$store.state.scroll = 0;
    },
  },
  mounted() {
    window.addEventListener("mousewheel", this.mousewheelHandler, {
      passive: false,
    });
    let timer = null;
    setTimeout(() => {
      this.setItem();
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        this.setItem();
      }, 500);
    };
  },
  methods: {
    arrownext1() {
      this.$refs.fullpage2.api.moveSectionDown();
      this.status = true;
    },
    toDetails(index) {
      if (index === 0) {
        this.$router.push(`/${localStorage.getItem("lans")}/pedelecs/f2`);
      } else {
        this.$router.push(`/${localStorage.getItem("lans")}/pedelecs/c2`);
      }
    },
    mousewheelHandler() {
      var e = e || window.event;
      if (e.wheelDelta) {
        if (e.wheelDelta > 0) {
          //当鼠标滚轮向上滚动时
          console.log("鼠标滚轮向上滚动1");
          this.$refs.fullpage2.api.moveSectionUp();
        }
        if (e.wheelDelta < 0) {
          //当鼠标滚轮向下滚动时
          console.log("鼠标滚轮向下滚动");
        }
      } else if (e.detail) {
        if (e.detail < 0) {
          //当鼠标滚轮向上滚动时
          console.log("鼠标滚轮向上滚动2");
        }
        if (e.detail > 0) {
          //当鼠标滚轮向下滚动时
          console.log("鼠标滚轮向下滚动");
        }
      }
    },
    setItem() {
      //第一屏的高度
      let height = this.$refs.fullpage0[0].offsetHeight;
      // title高度 + title顶部偏移量
      let filterH2 =
        this.$refs.top0[0].offsetHeight + this.$refs.top0[0].offsetTop;
      //探索更多的高度 - 探索更多的顶部偏移量
      let filterH3 =
        height -
        this.$refs.bottom0[0].offsetHeight -
        this.$refs.bottom0[0].offsetTop;
      //屏高 - （title高度 + title顶部偏移量）- （探索更多的高度 - 探索更多的顶部偏移量）
      let h = height - filterH2 - filterH3;
      let ii = this.$refs.imgB0[0];
      //助力车图片宽度
      let lv = ii.offsetWidth;
      //助力车图片高度
      let lv1 = ii.offsetHeight;
      // 图片宽高比
      let bi = 1542 / 1080;
      //助力车图片宽高比
      let box = lv / lv1;
      if (box >= bi) {
        this.imgBigHeight = h + "px";
        this.imgWidth = "auto";
        this.imgHeight = "100%";
      } else {
        this.imgBigHeight = h + "px";
        this.imgWidth = "90%";
        this.imgHeight = "auto";
      }
      this.isShow = true;
      // 判断宽高比
    },
  },
  components: {
    ToTop,
    pcFooter,
  },
  beforeDestroy() {
    window.removeEventListener("mousewheel", this.mousewheelHandler, {
      passive: false,
    });
  },
};
</script>

<style scoped lang="less">
@import "@/assets/less/common.less";
.product {
  position: relative;
  .content {
    .content-item {
      text-align: center;
      position: relative;
      img {
        height: 100%;
        transform: scale(0.65);
        @media screen and (max-width: 750px) {
          height: 100%;
          transform: scale(0.8);
        }
        @media screen and (max-width: 540px) {
          width: 100%;
          height: auto;
        }
      }
      img.active {
        animation: @animation;
        @media screen and (max-width: 750px) {
          animation: @animation1;
        }
      }
      .fp-tableCell {
        display: flex;
        align-items: center;
        height: 100%;
        .content-item-title {
          position: absolute;
          margin-left: 50%;
          transform: translateX(-50%);
          font-size: 36px;
          top: 100px;
          font-weight: bold;
          @media screen and (max-width: 1440px) {
            font-size: 32px;
          }
          @media screen and (max-width: 1280px) {
            font-size: 30px;
            top: 90px;
          }
          @media screen and (max-width: 980px) {
            .content-item-title {
              font-size: 28px;
            }
          }
          @media screen and (max-width: 540px) {
            font-size: 26px;
            top: 86px;
          }
        }
        .content-item-img {
          height: 500px;
          margin: 0 auto;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .content-item-img-bottom {
          position: absolute;
          margin-left: 50%;
          transform: translateX(-50%);
          bottom: 90px;
          padding: 8px 73px;
          border: 1px solid #030000;
          border-radius: 50px;
          font-size: 19px;
          font-weight: 400;
          color: #3e3a39;
          width: max-content;
          @media screen and (max-width: 540px) {
            bottom: 70px;
            padding: 6px 40px;
            font-size: 16px;
          }
        }
        .arrow1 {
          position: absolute;
          bottom: 25px;
          width: 100%;
          text-align: center;
          > img {
            width: 40px;
            cursor: pointer;
            @media screen and (max-width: 750px) {
              width: 40px;
            }
          }
        }
      }
    }
    #footer {
      height: calc(100vh);
      padding-top: 80px;
      @media screen and (max-width: 980px) {
        height: calc(100vh - 100px);
      }
      @media screen and (max-width: 750px) {
        padding-top: 60px;
      }
    }

    .htmlHeader {
      @media screen and (max-width: 750px) {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
